<template>
    <div>
        <v-row class="news" v-if="!isMobile">
            <v-col cols="12" class="news-title"  v-if="title">
                <h2>{{ title }}</h2>
            </v-col>
            <v-col cols="6" :md="isCategoryPage == true ? 4 : 3" class="product" v-for="p in products" :key="p.id">
                <img class="primary-img" :src="p.src ? p.src : 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png'" alt="" @click="goTo(`/product/${p.handle}`)">
                <div class="variants-list" v-if="p.variants.length > 1">
                    <img 
                        v-for="v in p.variants" 
                        :key="v.id" 
                        :src="p.images.filter(i => i.id == v.image_id).length > 0 ? p.images.filter(i => i.id == v.image_id)[0].src : (p.images.length > 0 ? p.images[0].src : 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png')" 
                        @click="p.src = p.images.filter(i => i.id == v.image_id).length > 0 ? p.images.filter(i => i.id == v.image_id)[0].src : (p.images.length > 0 ? p.images[0].src : 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png')"
                    >
                </div>
                <h3 class="title" @click="goTo(`/product/${p.handle}`)">{{ p.name.length > 43 ? `${p.name.substr(0, 43)}...` : p.name }}</h3>
                <p class="rate">
                </p>
                <p class="price" @click="goTo(`/product/${p.handle}`)">{{ parseFloat(p.sale_price).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</p>
            </v-col>
        </v-row>
        <v-row class="news-mobile" v-if="isMobile">
            <v-col cols="12" class="news-title" v-if="title">
                <h2>{{ title }}</h2>
            </v-col>
            <v-col cols="6" class="product" v-for="p in products" :key="p.id">
                <img class="primary-img" :src="p.src ? p.src : 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png'" alt="" @click="goTo(`/product/${p.handle}`)">
                <div class="variants-list" v-if="p.variants.length > 1">
                    <img 
                        v-for="v in p.variants" 
                        :key="v.id" 
                        :src="p.images.filter(i => i.id == v.image_id).length > 0 ? p.images.filter(i => i.id == v.image_id)[0].src : (p.images.length > 0 ? p.images[0].src : 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png')" 
                        @click="p.src = p.images.filter(i => i.id == v.image_id).length > 0 ? p.images.filter(i => i.id == v.image_id)[0].src : (p.images.length > 0 ? p.images[0].src : 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png')"
                    >
                </div>
                <h3 class="title" @click="goTo(`/product/${p.handle}`)">{{ p.name.length > 43 ? `${p.name.substr(0, 43)}...` : p.name }}</h3>
                <p class="rate">
                </p>
                <p class="price" @click="goTo(`/product/${p.handle}`)">{{ parseFloat(p.sale_price).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'FourProducts',
    props: {
        isMobile: Boolean,
        products: Array,
        title: String,
        isCategoryPage: Boolean
    },
    methods: {
        goTo(path){
            this.$router.push(this.$route.params.storeSlug == undefined ? `${path}` : `/${this.$route.params.storeSlug}${path}`)
        }
    }
}
</script>

<style scoped>
    /* NEWS */
    .body .news .news-title{
        text-align: center;
        text-transform: uppercase;
        font-family: Exo;
        font-size: 34px;
        font-weight: 700;
        line-height: 63.79px;
        color: #000;
        margin-top: 100px;
    }

    .body .news .product{
        cursor: pointer;
        padding-top: 50px;
    }

    .body .news .product .primary-img{
        width: 320px;
        height: 320px;
        display: block;
        margin: 0 auto;
        border-radius: 10px;
    }

    .body .news .product .title{
        font-family: Exo;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 26.58px;
        text-align: center;
        text-transform: uppercase;
    }

    .body .news .product .rate{
        margin-top: 10px;
        font-family: Exo;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.26px;
        text-align: left;
        color: #00000066;
    }

    .body .news .product .price{
        font-family: Exo;
        font-size: 22px;
        font-weight: 700;
        line-height: 31.9px;
        text-align: center;
        margin-top: -10px;
    }

    .news .variants-list{
        margin: 0 auto;
        width: 320px;
        overflow: auto;
        white-space: nowrap
    }

    .news .variants-list img{
        width: 50px !important;
        height: 50px !important;
        border: 1px solid black;
        margin-right: 5px;
    }


    /* NEWS MOBILE */
    .body .news-mobile .news-title{
        text-align: center;
        text-transform: uppercase;
        font-family: Exo;
        font-size: 28px;
        font-weight: 700;
        line-height: 63.79px;
        color: #000;
        margin-top: 100px;
    }

    .body .news-mobile .product{
        cursor: pointer;
        padding-top: 10px;
    }

    .body .news-mobile .product .primary-img{
        width: 100%;
        border-radius: 10px;
    }

    .body .news-mobile .product .title{
        font-family: Exo;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 26.58px;
        text-align: center;
    }

    .body .news-mobile .product .rate{
        margin-top: 10px;
        font-family: Exo;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.26px;
        text-align: left;
        color: #00000066;
    }

    .body .news-mobile .product .price{
        font-family: Exo;
        font-size: 22px;
        font-weight: 700;
        line-height: 31.9px;
        text-align: center;
        margin-top: -10px;
    }

    .news-mobile .variants-list{
        width: 100%;
        overflow: auto;
        white-space: nowrap
    }

    .news-mobile .variants-list img{
        width: 50px !important;
        height: 50px !important;
        border: 1px solid black;
        margin-right: 5px;
    }
    
    
</style>